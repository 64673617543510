@import '../../../styles/colors';

.wrapper_header {
  padding: 0.5rem 1rem;
  background: #ffffff;
  .drawer_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1330px;
    margin: 0 auto;
    .navbar-brand {
      max-width: 200px;
    }
    .btn_menu {
      display: none;
      margin-left: 0.75rem;
      width: 30px;
      height: 20px;
      position: absolute;
      top: 30px;
      right: 15px;
      z-index: 2;
      cursor: pointer;
      &:hover {
        color: $dark-blue;
      }
      & span {
        width: 100%;
        height: 4px;
        border-radius: 4px;
        display: block;
        position: absolute;
        background: $light-blue;
        transition: all 0.35s ease;
        transform-origin: 0px 100%;
        &:nth-child(2) {
          top: calc(50% - 2px);
        }
        &:nth-child(3) {
          bottom: 0;
        }
      }
      &.is_visible {
        position: fixed;
        top: 30px;
        right: 15px;
        span:nth-child(1) {
          transform: rotate(45deg) translate(-2px, 0px);
        }
        span:nth-child(2) {
          opacity: 0;
        }
        span:nth-child(3) {
          transform: rotate(-45deg) translate(-3px, 2px);
        }
      }
    }
    .drawer_navbar {
      .box_opts_link {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .ico_header {
          img {
            height: 30px;
          }
        }
        .navbar-nav {
          display: block;
          .nav-item {
            .nav-link {
              color: $light-blue;
              padding: 0 0.5rem;
              font-weight: 600;
              font-size: 14px;
            }
          }
        }
      }
      .box_logo_mobile,
      .box_social_mobile {
        display: none;
      }
      .box_opts_logeado {
        .opts_desktop {
          display: flex;
          align-items: center;
          .nav-link {
            font-weight: 600;
            color: #111;
            font-size: 14px;
          }
        }
        .opts_mobile {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .navbar-collapse {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
    margin-top: 4px;
  }
  .box_navbar {
    background: #ffffff;
    justify-content: flex-start;
    padding: 1rem 0.5rem 0.5rem;
    .box_opts_link {
      .ico_header {
        display: none;
      }
      .navbar-nav {
        .nav-item {
          .nav-link {
            padding-bottom: 0.75rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 660px) {
  .wrapper_header {
    .drawer_header {
      .btn_menu {
        display: block;
        &.is_visible {
          & span {
            background: #ffffff;
          }
        }
      }
      .drawer_navbar {
        overflow: auto;
        background: $dark-blue;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        justify-content: space-between;
        align-items: center;
        display: none;
        flex-direction: column;
        &.is_active {
          display: flex;
        }
        .box_opts_link {
          justify-content: center;
          flex-direction: column;
          width: 100%;
          margin-top: 10%;
          padding-bottom: 35px;
          border-bottom: 2px solid $active;
          .ico_header img {
            height: 50px;
            margin-right: 0.25rem;
          }
          .navbar-nav {
            display: flex;
            flex-direction: row;
            margin-top: 1rem;
            .nav-item {
              .nav-link {
                font-size: 18px;
                padding: 0 1rem;
                line-height: 1;
                color: #ffffff;
                border-right: 2px solid #ffffff;
              }
              &:last-child .nav-link {
                border-right: 0;
              }
            }
          }
        }
        .box_opts_logeado {
          width: 100%;
          margin-top: 10%;
          .opts_desktop {
            display: none;
          }
          .opts_mobile {
            display: block;
            padding-bottom: 25px;
            .opts_mobile_user {
              border-bottom: 2px solid $active;
              color: #ffffff;
              text-align: center;
              text-transform: uppercase;
              font-weight: 600;
              padding: 1rem;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                margin-right: 0.5rem;
                height: 35px;
              }
            }
            .opts_mobile_links {
              text-align: center;
              .nav-link {
                display: block;
                color: #ffffff;
                font-weight: 600;
                cursor: pointer;
                &:first-child {
                  margin-top: 0.5rem;
                }
              }
            }
          }
        }
        .box_logo_mobile {
          display: block;
          img {
            height: 85px;
          }
        }
        .box_social_mobile {
          display: block;
          width: 100%;
          text-align: center;
          padding: 1rem 0 1rem;
          a {
            padding: 0 0.75rem;
          }
        }
      }
    }
  }
}
