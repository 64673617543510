.m2_title {
  font-family: 'Montserrat', sans-serif !important;
  color: $blue;
  font-size: 55px;
  font-weight: 700;
  font-style: normal;
  line-height: 40px;

  &.t_small {
    font-size: 40px;
  }
}

.font_ttl {
  font-family: 'Montserrat', sans-serif !important;
}

.fw-200 {
  font-weight: 200;
}

.fw-400 {
  font-weight: 400;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.m2_paragraph {
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.35;

  &.is_p_small {
    font-size: 16px;
    line-height: 1.5em;
  }
}

.text-blue {
  color: $blue;
}
